import React from "react";

class ChatwootWidget extends React.Component {
    componentDidMount() {
        window.chatwootSettings = {
            hideMessageBubble: false,
            position: "right",
            locale: "en",
            type: "standard",
        };
        this.loadChatwootScript();
        this.showBubble();
    }

    componentWillUnmount() {
        this.unloadChatwootScript();
        this.hideBubble();
    }

    loadChatwootScript() {
        (function (d, t) {
            var BASE_URL = "https://chat.wondermachine.ai";
            var g = d.createElement(t),
                s = d.getElementsByTagName(t)[0];
            g.src = BASE_URL + "/packs/js/sdk.js";
            g.defer = true;
            g.async = true;
            g.id = "chatwoot-sdk-script";
            s.parentNode.insertBefore(g, s);
            g.onload = function () {
                window.chatwootSDK.run({
                    websiteToken: "q53j4KzPt7ysevzWtCXFe6MR",
                    baseUrl: BASE_URL,
                });
            };
        })(document, "script");
    }

    unloadChatwootScript() {
        const script = document.getElementById("chatwoot-sdk-script");
        if (script) {
            script.parentNode.removeChild(script);
        }
    }

    hideBubble() {
        const cw_widget_holder = document.getElementById("cw-widget-holder");
        if (cw_widget_holder) {
            // hide it
            cw_widget_holder.style = "display: none";
        }
        const cw_bubble_holder = document.getElementById("cw-bubble-holder");
        if (cw_bubble_holder) {
            cw_bubble_holder.style = "display: none";
        }
    }

    showBubble() {
        const cw_widget_holder = document.getElementById("cw-widget-holder");
        if (cw_widget_holder) {
            // hide it
            cw_widget_holder.style = "display: block";
        }
        const cw_bubble_holder = document.getElementById("cw-bubble-holder");
        if (cw_bubble_holder) {
            cw_bubble_holder.style = "display: block";
        }
    }

    render() {
        return null;
    }
}

export default ChatwootWidget;
